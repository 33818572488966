import { schema } from 'normalizr'
import { List } from 'immutable'

import Budget from './budget'
import Category from './category'
import Model from './model'
import ReasonToRefuse from './reason-to-refuse'
import ServiceOrderLink from './service-order-link'

class ServiceOrder extends Model({
  agency: undefined,
  agencyCompletionPercentage: undefined,
  actionResponsible: undefined,
  activeBudget: undefined,
  referaCompletionPercentage: undefined,
  attachments: undefined,
  bitrixOrderId: undefined,
  budget: new Budget(),
  category: undefined,
  clientType: undefined,
  closedAt: undefined,
  contactName: null,
  contactNumber: null,
  contactNumbers: [],
  companyName: null,
  firstContactSecondary: null,
  secondContactSecondary: null,
  contactEmail: null,
  contactCpfCnpj: null,
  createdAt: undefined,
  dateAndTimeFinish: undefined,
  datetimeFinished: undefined,
  datetimeExecutionBudgetApproved: undefined,
  datetimeExecutionScheduled: undefined,
  datetimeBudgetRevised: undefined,
  // datetimeBudgetScheduled: undefined,
  // datetimeExecutionInvoiceSent: undefined,
  // datetimeExecutionProofsSent: undefined,
  // datetimeExecutionRegistered: undefined,
  deadline: undefined,
  description: undefined,
  duplicatedFrom: undefined,
  executedAt: undefined,
  executionProof: undefined,
  hasNotViewedPendingHelpRequest: undefined,
  id: undefined,
  isActive: undefined,
  invoiceId: undefined,
  isPaused: undefined,
  item: null,
  localId: undefined,
  nextAppointment: undefined,
  notViewedPendingHelpRequestCount: undefined,
  orderStatus: undefined,
  originalDescription: undefined,
  otherReasonToRefuse: undefined,
  priority: undefined,
  property: undefined,
  propertyCode: undefined,
  propertyKeysLocation: undefined,
  reasonToMoveFurniture: undefined,
  reasonForCancellation: undefined,
  serviceOrderCancelDetails: undefined,
  propertyWhatsapp: null,
  propertyId: undefined,
  propertyEmail: null,
  propertyName: null,
  payerVerificationToken: null,
  propertyCpfOrCnpj: null,
  responsible: undefined,
  room: null,
  serviceOptions: null,
  serviceOrderDuplications: undefined,
  services: new Category(),
  serviceTypes: null,
  stage: undefined,
  subcategories: null,
  tradesman: undefined,
  tradesmanUser: undefined,
  unread: false,
  visitSchedule: undefined,
  stepDesc: undefined,
  stepStatus: undefined,
  status: undefined,
  optionsSelectedReprovedService: List(),
  reasonsReproveService: null,
  serviceorderRatingRequester: undefined,
  datetimeBudgetScheduled: undefined,
  hasProposeOpened: undefined,
  // datetimeBudgetOrderSelected: undefined,
  location: undefined,
  externalId: undefined,
  totalPrice: null,
  firstSuggestionDate: null,
  firstStartTime: null,
  firstEndTime: null,
  secondSuggestionDate: null,
  secondStartTime: null,
  secondEndTime: null,
  thirdSuggestionDate: null,
  thirdStartTime: null,
  thirdEndTime: null,
  reasonToRefuse: new ReasonToRefuse(),
  serviceOrderLink: new ServiceOrderLink(),
  distanceBudget: undefined,
  rentValue: undefined,
  approvedMaximumValue: undefined,
  contractStartDate: undefined,
  responsibleTeam: null,
  requestType: null,
  lastInternshipAdvanced: null,
  classification: null,
  isVipClient: null,
  isCancelled: false,
  controlScheduleWhatsapp: null,
  uuid: null,
  requesterRatingFeature: false,
  lastStepLogDetails: null,
  reprovedReason: null,
  allowSendAttachmentInvoice: false,
  negativeFeedback: null,
  managedBy: null,
  serviceOrderType: null,
  businessFront: undefined,
  contractCode: null,
  emergency: false,
  specialClassifications: undefined,
  hasAnotherBudget: null,
  isReferaService: false,
}) {}

const serviceOrderNewSchema = new schema.Entity(
  'serviceOrder',
  {},
  {
    processStrategy: entity => new ServiceOrder(entity).set('propertyCode', entity?.property?.code),
  }
)

const serviceOrderSchema = [serviceOrderNewSchema]

export { serviceOrderSchema, ServiceOrder as default }
