import { schema } from 'normalizr'

import Model from './model'

class HistoryLog extends Model({
  id: undefined,
  logType: undefined,
  message: undefined,
  url: undefined,
  iconType: undefined,
  iconBackgroundColor: undefined,
  iconColor: undefined,
  date: undefined,
  title: undefined,
  user: undefined,
  userName: undefined,
  reasonsReproveService: undefined,
  optionsSelectedReprovedService: undefined,
  isActive: undefined,
  rating: undefined,
  moreDetailsModalTitle: undefined,
}) {}

const historyNewSchema = new schema.Entity(
  'historyLog',
  {},
  {
    processStrategy: entity => new HistoryLog(entity),
  }
)

const historySchema = [historyNewSchema]

export { historySchema, HistoryLog as default }
